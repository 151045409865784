.DeliveryDate {
}

.DeliveryDate h2 {
  text-align: center;
  color: var(--white);
}

.options {
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  flex-wrap: wrap;
}

.option {
  max-width: 400px;
  flex-basis: 300px;
  flex-shrink: 1;
  flex-grow: 1;
  border: 6px var(--yellow) solid;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: var(--white);
  color: var(--black);
  padding: var(--pad);
}

.when {
  margin-bottom: var(--pad);
}
.when strong {
  color: var(--orange);
}
.option h3 {
  font-size: 24px;
  margin: 0;
}

.flames svg {
  display: inline;
  margin-left: -5px;
}
.flames svg:nth-child(2) path {
  fill: var(--orange);
}
.flames svg:nth-child(3) path {
  fill: var(--red);
}

.button {
  text-align: right;
  margin-top: var(--pad);
  width: 100%;
}

.price_per_litre {
  font-size: 14px;
  margin-bottom: var(--pad);
}

.total_exclude_vat {
  font-size: 14px;
}

.price strong {
  font-size: 1.5rem;
  font-weight: bold;
}
