.UspBar {
  margin-top: var(--pad);
}

.UspBar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.UspBar li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--black);
  font-weight: bold;
  font-size: 18px;
}

.UspBar li svg {
  margin-right: 0.5rem;
  width:46px;
  height: 46px;
}