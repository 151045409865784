.Requirements {
}

.header {
  text-align: center;
}

.fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-evenly;
  margin-top: var(--pad);
}

.field {
  display: flex;
  flex-direction: column;
  flex-basis: 320px;
  max-width: 100%;
}

.field label {
  font-weight: bold;
  text-transform: uppercase;
}

.input {
  border-radius: 0.5rem;
  outline: none;
  padding: 0.5rem;
  border: 1px solid #707070;
}

.postcode{
  text-transform: uppercase;
}
.postcode::placeholder{
  text-transform: none;
}
.checkField {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}
.checkField label {
  font-weight: normal;
  font-size: 1rem;
  cursor: pointer;
  text-transform: none;
}

.checkField input {
  border: 1px solid #707070;
  transform: scale(1.5);
  margin-left: 5px;
}
