@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
  --white: #ffffff;
  --black: #3a3a3a;
  --red: #c11726;
  --orange: #eb5d12;
  --blue: #11a0eb;
  --yellow: #f5a900;
  --off-white: #EFDCD2;

  --border-radius: 1rem;
  --pad: 1rem;
}

.App {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}
