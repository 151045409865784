.Delivery {
}

.Delivery h2 {
  color: var(--white);
  margin-bottom: 0;
}
.form {
  display: flex;
  flex-wrap: wrap;
  gap: 0rem 3rem;
}

.fields {
  display: flex;
  gap: 0rem 2rem;
  flex-wrap: wrap;
  flex-grow: 1;
}

.field {
  display: flex;
  flex-direction: column;
  flex-basis: 370px;
  max-width: 100%;
  margin-top: 1rem;
}

@media screen and (max-width: 480px) {
  .field {
    flex-basis: 100%;
  }
}

.field label {
  font-weight: bold;
  text-transform: uppercase;
}

.input {
  border-radius: 0.5rem;
  outline: none;
  padding: 0.5rem;
  border: 1px solid #707070;
  text-transform: none;
}
.input:disabled {
  background-color: transparent;
  border: 1px solid #fff;
  color: var(--white);
}
.fullfields {
  width: 100%;
}

.checkField {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.checkField label {
  font-weight: normal;
  font-size: 1rem;
  cursor: pointer;
  text-transform: none;
}

.checkField input {
  border: 1px solid #707070;
  transform: scale(1.5);
  margin-left: 5px;
}

.button {
  width: 100%;
  text-align: left;
  margin-top: 1rem;
}

.field textarea{
  min-height: 8rem;
}

@media screen and (max-width: 960px) {
  .button {
    text-align: center;
  }
}


.disabled{
  opacity: 0.2;
}