.Fuel {
}

.Fuel h2 {
  text-align: center;
  color: var(--white);
}
.flames {
  display: flex;
}
.flames svg {
  margin-left: -5px;
  fill: var(--blue);
}
.flames svg:nth-child(2) path {
  fill: #0151be;
}
.flames svg:nth-child(1) path {
  fill: var(--blue);
}

.options {
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  flex-wrap: wrap;
}

.option h3 {
  font-size: 24px;
  margin: 0;
}

.option {
  max-width: 550px;
  flex-basis: 300px;
  flex-shrink: 1;
  flex-grow: 1;
  border: 6px var(--blue) solid;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: var(--white);
  color: var(--black);
  padding: var(--pad);
  position: relative;
}

.button {
  text-align: right;
  margin-top: var(--pad);
}

@media screen and (min-width: 1080px) {
  .button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  .totals {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }
}

.total_exclude_vat {
  font-size: 14px;
}

.price strong {
  font-size: 1.5rem;
  font-weight: bold;
}

.option ul {
  list-style: none;
  margin: 1rem 0;
  display: grid;
  padding: 0;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  font-size: 0.8rem;
  font-weight: bold;
}

.option:first-child ul {
  grid-template-columns: 1fr;
}

.option li::before {
  content: "-";
  margin-right: 0.5rem;
}

@media screen and (max-width: 1080px) {
  .option {
    display: flex;
    flex-direction: column;
  }
  .totals {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .button Button {
    width: 100%;
  }
}

.price_per_litre {
  font-size: 14px;
  margin-bottom: var(--pad);
  font-weight: bold;
}
