.wrap {
    justify-content: center;
    display: flex;
}


.spinner {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: 1rem solid var(--off-white);
	border-top-color: var(--red);
	animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(1turn);
	}
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(1turn);
	}
}
