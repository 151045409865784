.Progress {
  background: var(--off-white);
  color: var(--black);
  border-radius: var(--border-radius);
  padding: var(--pad);
}

.Progress h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--black);
}

.Progress table {
  width: 100%;
}

.Progress td {
  padding: 0 8px;
  overflow-wrap: anywhere;
}

.Progress th {
  text-align: left;
  padding-right:1em;
}

.info {
  margin: 0 7px;
  display: flex;
}

.step {
  width: 33%;
  justify-content: space-between;
  margin-left: 0px;
}
.stepInner {
  margin-left: 4px;
  border-left: 1px solid var(--black);
  padding: var(--pad);
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.progressBarContainer {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  position: relative;
  width: 100%;
}

.progressBarContainer::before {
  background-color: var(--black);
  content: "";
  height: 16px;
  left: 10px;
  right: 5px;
  position: absolute;
  top: 58%;
  transform: translateY(-50%);
  z-index: 0;
}

.progressBar {
  background-color: var(--white);
  height: 7px;
  left: 16px;
  right: 16px;
  position: absolute;
  top: 58%;
  transform: translateY(-50%);
  transition: 0.4s ease;
  width: 0%;
  z-index: 3;
}

.circle {
  align-items: center;
  background-color: #afafaf;
  border: 5px solid var(--black);
  border-radius: 50%;
  color: var(--text-color);
  display: flex;

  justify-content: center;
  transition: 0.4s ease;
  margin-top: 0.2rem;
  width: 26px;
  height: 26px;
  z-index: 2;
}

.circle.active {
  background-color: var(--white);
}

.Progress td,
.Progress th {
  padding: 0;
}

.Progress Button {
  margin: 0.5rem 0;
}

@media screen and (max-width: 960px) {
  .step {
    display: block;
    width: 100%;
  }
  .info {
    flex-wrap: wrap;
  }
  .stepInner {
    border-left: none;
    margin-left: 0px;
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .Progress tr {
    display: flex;
    flex-wrap: wrap;
  }
  .Progress td {
    width: 100%;
  }
}
.summmary {
  padding: var(--pad);
}
