.Quote {
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: var(--pad);
  color: var(--black);
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.inner {
  background-color: var(--red);
  color: var(--white);
  border-radius: var(--border-radius);
  padding: var(--pad);
}

.title {
  display: flex;
  margin-bottom: var(--pad);
  flex-direction: column;
  align-items: center;
}
.title h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  color:var(--white);
}

@media screen and (max-width: 480px) {
  .remove {
    display: none;
  }
}