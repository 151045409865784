.Button {
  background-color: var(--blue);
  padding: 1rem 3rem;
  outline: none;
  border: none;
  color: var(--white);
  font-size: 2rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .Button.large {
    font-size: 1.5rem;
  }
}

.Button.small {
  font-size: 1rem;
  padding: 0.5rem 3rem;
  border-radius: 5px;
}
.Button.tiny {
  font-size: 0.8rem;
  border-radius: 5px;
  padding: 0.5rem;
}
.Button:hover {
  background-color: #184b84;
  border: none;
  color: var(--white);
}
